@import "~rupture-sass/rupture";
@import "node_modules/jeet/jeet";
@import "mixins.scss";
@keyframes colors {
  from {
    filter: invert(0) hue-rotate(0deg);
  }
  to {
    filter: invert(0) hue-rotate(360deg) saturate(2);
  }
}
* {
  box-sizing: border-box;
  @include hideselect();
}
.mobile {
  @include above(1025px) {
    display: none !important;
  }
}
.desktop {
  @include below(1024px) {
    display: none !important;
  }
}
html {
  @include below(1024px) {
    font-size: 2vw;
  }
  @include above(1025px) {
    font-size: 0.9vw;
  }
}
body {
  margin: 0;
  padding: 0;
  color: #000;
  background: #d63232;
  font-size: 1rem;
}
#loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #d63232;
  z-index: 999999999;
}
#background-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background: #000;
  // .ball {
  //   background: #f9f6c6;
  //   position: absolute;
  //   width: 20rem;
  //   height: 20rem;
  //   border-radius: 100%;
  //   @include below(1024px) {
  //     width: 25rem;
  //     height: 25rem;
  //   }
  // }
  &.animate {
    background: #f9f6c6;
  }
}
#background-foreground {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(./assets/images/bg.png);
  background-size: 4.5rem;
  z-index: 1;
  @include below(1024px) {
    background-size: 7.4rem;
  }
}
// #preview {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-image: url(./assets/images/preview.png);
//   background-size: 100%;
// }
#viewport {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  @include below(1024px) {
    @include span(1);
    position: static;
    padding: 3rem 0;
    & > *:not(#images) {
      padding: 0 2rem;
    }
  }
}

#insta {
  display: none;
  cursor: pointer !important;
  top: 12rem;
  left: 5rem;
  width: 9rem;
}

#logo {
  width: 75%;
  z-index: 1 !important;
  @include align();
  @include below(1024px, $orientation: portrait) {
    width: 90vh !important;
    transform: translate3d(-50%, -50%, 0) rotate(-90deg);
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
  }
}
#welcome {
  top: 4.6rem;
  left: 11.4rem;
  width: 21.3rem;
}
#story_sauce {
  bottom: 10rem;
  left: calc(40% - 18.2rem);
  width: 40rem;
}
#typo_download {
  display: block;
  top: 2rem;
  right: 21rem;
  width: 16.6rem;
  cursor: pointer !important;
  img {
    width: 100%;
  }
}
#credits {
  bottom: 2rem;
  left: calc(50% + 3rem);
  width: 18rem;
  cursor: pointer !important;
}
.text-image {
  display: flex;
  flex-wrap: wrap;
  img {
    display: inline-block;
    height: 3.2rem;
    width: auto;
    margin-right: 0.1rem;
    margin-bottom: 0.7rem;
  }
}
.drag,
.reset,
.drawer {
  z-index: 3;
  position: absolute;
  @include below(1024px) {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    bottom: initial !important;
    right: initial !important; // transform: none !important;
    @include span(1);
    width: 100% !important;
    margin-bottom: 5rem;
    .grab {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.drawer {
  display: flex;
  z-index: 3;
  position: absolute;
  --drawer-size: 10rem;
  @include below(1024px) {
    &:not(#images) {
      width: 100% !important;
      height: auto !important;
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  &[data-direction="top"] {
    flex-direction: column-reverse;
    width: var(--drawer-size);
    bottom: 0;
    img {
      width: 100%;
    }
  }
  &[data-direction="bottom"] {
    flex-direction: column;
    width: var(--drawer-size);
    top: 0;
    img {
      width: 100%;
    }
  }
  &[data-direction="right"] {
    flex-direction: row;
    height: var(--drawer-size);
    left: 0;
    img {
      height: 100%;
    }
  }
  &[data-direction="left"] {
    flex-direction: row-reverse;
    height: var(--drawer-size);
    right: 0;
    img {
      height: 100%;
    }
  }
  .grab {
    cursor: -webkit-grab;
  }
}

#titre {
  top: calc(50% - 10rem);
  --drawer-size: 18.5rem;
}

#ingredients {
  right: 5.1rem;
  --drawer-size: 27.5rem;
}

#images {
  left: calc(50% - 20.5rem);
  translate: translate3d(-50%, 0, 0);
  --drawer-size: 45rem;
}

#images-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  @include below(1024px) {
    height: 71.7rem;
    .hole {
      height: 100%;
      mask-image: url("./assets/images/mobile/slider-mask_mobile.png");
      -webkit-mask-image: url("./assets/images/mobile/slider-mask_mobile.png");
      mask-size: 100% 100%;
      -webkit-mask-size: 100% 100%;
    }
  }
  @include above(1025px) {
    height: calc(var(--drawer-size) * 116.8 / 100);
    // height: calc(100vh - 11rem);
    // width: calc((100vh - 11rem) * 0.857);
    // transform: translate(calc(-50% + var(--drawer-size) / 2));
    .hole {
      height: 100%;
      mask-image: url("./assets/images/slider-mask.png");
      -webkit-mask-image: url("./assets/images/slider-mask.png");
      mask-size: 100% 100%;
      -webkit-mask-size: 100% 100%;
    }
  }
  .inner {
    display: flex;
    height: 100%;
    img {
      height: 100%;
      width: auto;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

#infos {
  bottom: 3.5rem;
  --drawer-size: 19rem;
  .adulte {
    position: absolute;
    z-index: 1;
    width: 21%;
    height: 14%;
    top: 72%;
    left: 54%;
  }
  .tristan {
    position: absolute;
    z-index: 1;
    width: 21%;
    height: 14%;
    top: 87%;
    left: 11%;
  }
}

#shop {
  top: calc(50% - 1rem);
  --drawer-size: 14rem;
}

[event-target="link"] {
  cursor: pointer !important;
}
